/* Reset default margin and padding */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* Include padding and border in element's total width and height */
  }
  @font-face {
    font-family: 'RoxboroughCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */
  
    src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'VisbyCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */
  
    src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'VisbyCF-Bold';
  
  
    src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
  }
  .main-footer {
    color: #030605;;
   
    /* background-color: rgb(224, 34, 34); */
    padding: 20px 0; /* Adjust as needed */
    width: 100%;
    /* border: solid green 2px; */
  }
  
  .list-unstyled li {
    font-family: 'VisbyCF';
    font-size: 20px;
    font-weight: 600;
    line-height: 1.7;
    text-align: left;
    color: #030605;
  }
  .middle-footer{
    padding-left: 1rem;
  }
  .middle-footer h4 {
    font-family: 'VisbyCF';
    font-size: 18px;
    font-weight: 600;
    line-height: 1.47;
    letter-spacing: 0.36px;
    text-align: left;
    color: #000;
  }
  
  .list-unstyled-1 li  {
    font-family: 'VisbyCF' !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 2.66 !important;
    letter-spacing: 0.3px !important;
    text-align: left !important;
    color: #5c5e5e !important;
    list-style: none !important;
    
  }
  .footer_links_bottom_custom{
    font-family: 'VisbyCF' !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 2.66 !important;
    letter-spacing: 0.3px !important;
    text-align: left !important;
    color: #5c5e5e !important;
  }
  .last-footer li{
    display: flex;
    /* width: 95px;
    height: 23px; */
    /* margin: 39.6px 50px 39px 182px; */
    justify-content: space-around;
    font-size: 15px;
    text-align: right;
    color: #5c5e5e;

  }
  .last-footer li span, p{
    font-family: 'VisbyCF';
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #5c5e5e;
  }
  