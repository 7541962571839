@font-face {
  font-family: 'RoxboroughCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
}

@font-face {
  font-family: 'VisbyCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
  font-family: 'VisbyCF-Bold';


  src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
}

.working_with_container{
    background-image: url("../../assets/Furniture.png");
    width:100%;
    background-repeat: no-repeat;
    background-size: cover;
    height:600px;
    
}
.Section-Title h1{
    /* width: 678px;
    height: 190px;
    margin: 80px 232px 38px 0; */
    font-family: 'RoxboroughCF';
    font-size: 92px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
  }
  .Section-Title p{
    
 
  margin: 38px 95px 38px 0;
  font-family: 'VisbyCF';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: left;
  color: #030605;

  }
  .btn-explore-now{
    width: 196px  !important;
    height: 52px !important;
    display: flex !important;
    flex-direction: row !important;
    /* justify-content: flex-start ; */
    align-items: center;
    gap: 10px;
    margin: 6px 448px 0 0;
    padding: 16px 18px;
    background-color: #28887a;
    text-align: center;
  }
  .btn-explore {
    width: 132px;
    height: 52px !important;
    display: flex !important;
    flex-direction: row !important;
    /* justify-content: flex-start ; */
    align-items: center;
    gap: 10px;
    margin: 6px 448px 0 0;
    padding: 16px 18px;
    background-color: #28887a;
    text-align: center;
  }
  .btn-text {
    width: 100% !important;
    height: 20px;
    flex-grow: 0;
    font-family: 'VisbyCF';
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: right;
    color: #fff;
  }
  .parent_div_btn_arrow{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width:80%;
  }
  .right_arrow_black{
    margin-left: -2rem !important
  }
  @media (min-width: 300px) and (max-width: 770px) {


  }
  @media (min-width: 1300px) and (max-width: 1400px) {
    .Section-Title h1{
     
      font-size: 62px;
      
    }

  }
  @media (min-width: 1200px) and (max-width: 1299px) {
    .Section-Title h1{
     
      font-size: 62px;
      
    }
    .working_with_container{
     
    
      height:500px;
   
  }

  }
  @media (min-width: 1000px) and (max-width: 1199px) {
    .Section-Title h1{
     
      font-size: 46px;
      line-height: 1;
      
    }
    .working_with_container{
     
    
      height:450px;
   
  }
  .Section-Title p {
    margin: 5px 0px 5px 0px;
    

  }
}

@media (min-width: 800px) and (max-width: 999px) {
  .Section-Title h1{
     
    font-size: 30px;
    line-height: 1;
 
    
  }
  .working_with_container{
   
  
    height:520px;
 
}
  .Section-Title{
    padding-top: 2.6rem !important;
    
}
.Section-Title p {
  margin:  0px;
  width:100%;
  font-size: 18px;

}

.working_right_image img{
  width:70%;
  padding-top: 2rem !important;
}
.btn-explore {
 
  margin: 6px 300px 20px 0;


}

}

@media (min-width: 300px) and (max-width: 770px) {
  .Section-Title h1{
     
    font-size: 25px;
    line-height: 1;
 
    
  }
  .working_with_container{
    background-image: url("../../assets/mobile.png");
  background-size:100% 100%;
    height:470px;
 
}
  .Section-Title{
    padding-top: 1.3rem !important;
    
}
.Section-Title p {
 display: none;

}

.working_right_image img{
  width: 132px;
  height: 199px;
  padding-top: 0rem !important;
 
}
.btn-explore {
 
  margin: 6px 30px 20px 0;


}
.btn-explore {
  width: 120px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin:  0 0 !important;
  padding: 16px 18px;
  background-color: #28887a;
  text-align: center;
  
}
.btn-text {
  width: 56px;
  height: 20px;
  flex-grow: 0;
  font-family: 'VisbyCF';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 1px;
  text-align: right;
  color: #fff;
}
.parent_div_btn_arrow{
  display: flex;
  justify-content: space-between !important;
  align-items:none !important;
  width:100%;
  flex-direction: row !important;
}
.right_arrow_black{
  margin-left: 1rem !important
}
}