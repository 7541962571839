
@font-face {
    font-family: 'RoxboroughCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

    src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
}

@font-face {
    font-family: 'VisbyCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

    src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
    font-family: 'VisbyCF-Bold';


    src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
}

.Provide_parent{
    background-image: url("../../assets/5.png");
  
    background-repeat: no-repeat;
    background-position: left top 00px;
    /* Adjusted position */
    background-size: 15%;
    /* Adjusted size */
    width: 100%;
    height: 300px;
    
}

.Provide_content_1 h1{
    height: 190px;
width:70%;
  font-family: 'RoxboroughCF';
  font-size: 82px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: right;
  color: #030605;
  float: right;

}

/* .provide-images-parent{
    display: flex ;
    width:100%;
    flex-direction: row ;
    overflow: hidden;
    position: relative;
    height: 600px;
}
.parent_images{
    position: absolute;
    left:0%;
    top:0%;
}
.provide_child{
    height:900px;
    border: solid red 2px;
    width:100%;
    background-image: url('../../assets/8.png');
    background-position: bottom 100px right ;
    background-repeat: no-repeat;

}
.provide_child img{
    width:61%;
    height: 69%;;
    position: absolute;
    bottom: 0%;
    right: 10%;

} */
.provide-images-parent img{
    max-width: fit-content;
    width:100%;
    z-index: 44 !important;

}
.provide-images-parent{
    background-image: url("../../assets/8.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 68% 70%;
    height: 960px;
    z-index: 44 !important;
}
.provide-images-parent_content_inner {
    align-items: center;
    justify-content: center;
    margin: auto;
    padding-left: 40%;
}

.provide-images-parent_content_inner p {
    font-family: 'VisbyCF';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left; /* Align text to the left */
    color: #030605;
    width: 70%;
    align-items: center !important; /* Remove this line */
   
}
.Abouts_btn button{
    width: 123px;
height: 52px;
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
gap: 10px;

padding: 16px 28px;
background-color: #28887a;
flex-grow: 0;
  font-family: 'VisbyCF';
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border: none;

}
.Abouts_btn{
    justify-content: space-between;
    display: flex;
    flex-direction: row;
 
    width:60%;
}
@media (min-width: 300px) and (max-width: 400px) {
    .Provide_content_1 h1 {
        height: 190px;
        width: 70%;
      
        font-size: 32px;
       
    }
    .Provide_parent {
       
        background-repeat: no-repeat;
        background-position: left top 00px;
        background-size: 70%;
        width: 100%;
        height: 260px;
    }
    .provide-images-parent {
       
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 100% 70%;
        height: 760px;
    }
    .provide-images-parent_content_inner p {
        font-family: 'VisbyCF';
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: #030605;
        width: 100%;
        align-items: center !important;
       
    }
    .provide-images-parent_content_inner {
        align-items: center;
        justify-content: center;
        margin: auto;
        padding-left: 0%;
        padding: 0.5rem;
    }
    .Abouts_btn {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        width: 90%;
    }

}
@media (min-width: 401px) and (max-width: 500px) {
    .Provide_content_1 h1 {
        height: 190px;
        width: 70%;
      
        font-size: 32px;
        padding-top: 2rem;
       
    }
    .Provide_parent {
       
        background-repeat: no-repeat;
        background-position: left top 00px;
        background-size: 70%;
        width: 100%;
        height: 260px;
    }
    .provide-images-parent {
       
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 100% 70%;
        height: 760px;
    }
    .provide-images-parent_content_inner p {
        font-family: 'VisbyCF';
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        text-align: left;
        color: #030605;
        width: 100%;
        align-items: center !important;
       
    }
    .provide-images-parent_content_inner {
        align-items: center;
        justify-content: center;
        margin: auto;
        padding-left: 0%;
        padding: 0.5rem;
    }
    .Abouts_btn {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        width: 90%;
    }

}

@media (min-width: 700px) and (max-width: 800px){

.Provide_content_1 h1 {
    background-image: url("../../assets/5.png");
    margin-top: -6.5rem !important;
    background-repeat: no-repeat;
    background-position: left  top  ;
    background-size: 100% 100%;
    width: 100%;
    height: 350px;
    
}
.Provide_parent {
   background-image: none;
    background-repeat: no-repeat;
    background-position:  00px;
    background-size:0% ;
    width: 100%;
    height: 0px;
}
.provide-images-parent {

    background-size: 78% 70%;
    height: 860px;
    margin-bottom: 1rem;
}
}
@media (min-width: 801px) and (max-width: 900px){
    .Provide_content_1 h1 {
        height: 190px;
        width: 96%;
        font-family: 'RoxboroughCF';
        margin-top: -1.8rem;
       
    }
    .provide-images-parent {
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 78% 70%;
        height: 860px;
        margin-bottom: 1rem;
    }

}

