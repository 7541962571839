
@font-face {
  font-family: 'RoxboroughCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
}

@font-face {
  font-family: 'VisbyCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
  font-family: 'VisbyCF-Bold';


  src: url('../assets/fonts/VisbyCF-Bold.otf') format('opentype');
}
.custom-container {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.zigzag-row {
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding: 0.2rem 2rem;
}

.col-left, .col-right {
  flex: 0 0 50%; /* Ensure columns take up 50% of the row */
  max-width: 50%; /* Ensure columns take up 50% of the row */
  padding-left: 0; /* Remove default padding */
  padding-right: 0; /* Remove default padding */
}

.col-left {
  margin-top: -30px; /* Adjust this value as needed */
}

.col-right {
  margin-top: 20px; /* Adjust this value as needed */
}

.box {
  width: 80%; /* Ensure the boxes take full width of the column */
  height: 298px;
  padding: 39px 61px 48px 58px;
  border-radius: 7px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center; /* Ensure padding and border are included in the element's total width and height */
}
.box-title h1{
  font-family: 'RoxboroughCF';
  font-size: 82px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  /* text-align: right; */
  color: #030605;
}
.box-title{
  background-color: none !important;
}
.box h3{
  font-family: 'VisbyCF';
  font-size: 50px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: -1.5px;
  /* text-align: left; */
  color: #030605;
}
.box p{
  font-family: 'VisbyCF';
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.7;
  letter-spacing: normal;
  text-align: center;
  color: #5c5e5e;
}


  .meet-hcube{
    /* margin: 73px 0 89px 127px; */
    padding: 84px 268px 195px 136px;
    background-color: #ffd476;
    border-radius: 0px 137.5px  0px 134px;


  }
  .meet-hcube-heading h4{
    font-family: 'VisbyCF-Bold';
  font-size: 30px;
  font-weight: bold;
  line-height: 1.21;
  /* letter-spacing: -0.7px; */
  }
  .meet-hcube-heading h1{
    font-family: 'RoxboroughCF';
    font-size: 56px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: -1.2px;
    text-align: left;
    color: #030605;

  }
   .meet-hcube-heading p{
    font-family: 'VisbyCF';
    font-size: 24px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
  }
  .side-container-borders {
    border-left: 2px solid #d3d3d3;  /* Adjust color and width as needed */
    border-right: 2px solid #d3d3d3; /* Adjust color and width as needed */
    padding-left: 10px;  /* Optional: Add padding if needed */
    padding-right: 10px; /* Optional: Add padding if needed */
}
.parent-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure content doesn't overflow the container */
  /* border: solid red 2px ; */
}
.parent-container-2 {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 300px; /* Adjust as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure content doesn't overflow the container */
  /* border: solid red 2px ; */
}
.background-text {
  position: absolute;
  top: 0;
  left: 3%;
  width: 724px;
  height: 175px;
  /* margin: 89px 447px 193px 8px; */
  opacity: 0.1;
  /* background-image: linear-gradient(181deg, #000 98%, #f8fbfa 17%); */
  background-image: linear-gradient(to bottom, #000 10%, rgba(248, 251, 250, 0.1) 100%);
  
  font-family: 'RoxboroughCF', sans-serif;
  font-size: 198px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: -5.94px;
  text-align: left;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent; /* For non-webkit browsers */
}
.background-text-2 {
  position: absolute;
  top: 0;
  right: 0%;
  /* width: 724px;
  height: 175px; */
  /* margin: 89px 447px 193px 8px; */
  opacity: 0.1;
  background-image: linear-gradient(to bottom, #000 10%, rgba(248, 251, 250, 0.1) 100%);
  
  font-family: 'RoxboroughCF', sans-serif;
  font-size: 198px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.85;
  letter-spacing: -5.94px;
  text-align: right;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent; /* For non-webkit browsers */
}
.overlay-box {
  
  width: 862px;
  height: 184px;
  /* margin: 208px 168px 65px 149px; */
  padding: 0 41px 24px 42px;
  opacity: 0.8;
  border-radius: 7px;
  margin-top: 2rem;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(239, 235, 235, 0.1),
              0 8px 30px rgba(239, 235, 235, 0.15),
              0 12px 60px rgba(239, 235, 235, 0.2),
              0 16px 100px rgba(239, 235, 235, 0.25);
}
.overlay-box p{
  text-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
  font-family: 'VisbyCF';
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: 0.75px;
  text-align: left;
  color: #5c5e5e;
  padding-top: 0.6rem;
}
@media (min-width: 300px) and (max-width: 800px){
  .meet-hcube {
    /* margin: 73px 0 89px 127px; */
    padding: 0.5rem;
    background-color: #ffd476;
    border-radius: 0px 137.5px 0px 134px;
}

.meet-hcube-heading h4{

font-size: 25px;

/* letter-spacing: -0.7px; */
}
.meet-hcube-heading h1{

  font-size: 36px;
 ;
  line-height: 1.21;
  letter-spacing: -1.2px;
 

}
 .meet-hcube-heading p{

  font-size: 18px;



}
.background-text {


  left: 3%;
 

  opacity: 0.1;
 
  font-size: 68px;
  font-weight: 900;
  background-image: linear-gradient(to bottom, #000 10%, rgba(248, 251, 250, 0.1) 100%);
  line-height: 0.85;
  letter-spacing: 0.94px;
 
}
.background-text-2 {
  position: absolute;
  top: 0;
  right: 0%;
 
  opacity: 0.1;
 
  font-size: 68px;
  font-weight: 900;
  letter-spacing: 0.94px;

}
.overlay-box {
  
  width: 862px;
  height: 214px;

  padding: 0 41px 24px 42px;

  margin-top: 2rem;
 
}
.overlay-box p{
width: 100% !important;
  font-size: 16px;
text-align: left;
  padding-top: 0.4rem;
}
.zigzag-row {
  display: flex;
  align-items: center;
  margin-left: 0;
  margin-right: 0;
  padding: 0.2rem ;
  flex-direction: column;
}

.col-left, .col-right {
  flex: 0 0 100%; /* Ensure columns take up 50% of the row */
  max-width: 100%; /* Ensure columns take up 50% of the row */
  padding-left: 0; /* Remove default padding */
  padding-right: 0; /* Remove default padding */
}
.box {
  width: 100%; /* Ensure the boxes take full width of the column */
  height: 298px;
  padding: 0.4rem 0rem 0rem 0rem;
  border-radius: 7px;
  background-color: #fff;
  box-sizing: border-box;
  text-align: center; /* Ensure padding and border are included in the element's total width and height */
}
.box-title h1{

  font-size: 42px;
  width:70%;
  line-height: 1.16;
  text-align: center;

}
.box-title{
  background-color: none !important;
}
.box h3{
 
  font-size: 30px;
 
  letter-spacing: 1.5px;
 
}
.box p{

  font-size: 17px;
  width:80%;
  text-align: center !important;
  margin: auto;
 
}
.col-left {
  margin-top: 20px; /* Adjust this value as needed */
}

.col-right {
  margin-top: 20px; /* Adjust this value as needed */
}
}