* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;


}
@font-face {
  font-family: 'RoxboroughCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
}

@font-face {
  font-family: 'VisbyCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
  font-family: 'VisbyCF-Bold';


  src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
}

.contact-body {
  background-color: rgba(248.0000004172325, 251.00000023841858, 250.00000029802322, 1) !important;

}

.fullname {



  /* margin: 0 210px 8px 48px; */
  font-family: 'VisbyCF';
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #2b324f;
}

.input_box_size {

  width: 100%;
  /* height: 42px; */
  flex-grow: 0;

  padding: 12px 104px 12px 12px;
  border-radius: 5px;
  border: solid 1.5px #f1f3f5 !important;
  background-color: #fff !important;

  flex-grow: 0;
  font-family: 'VisbyCF';
  font-size: 14px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #b8bbd8;

}

.input-box-size:focus {
  border: 1px solid #ced4da !important;
  /* Set border color when focused */
  box-shadow: none !important;
  /* Remove box shadow when focused */
}

.no-border:focus,
.no-border:active {
  border-color: none !important;
  box-shadow: none;
}

.form_bg {
  width: 100%;
  height: 614px;
  /* margin: 46px 145px 121px 153px; */
  padding: 45px;
  border-radius: 6px;
  background-color: #fff;
  

}

.form.control {
  border: solid 1.5px #f1f3f5;
  background-color: #fff;

}
.submit_btn{
  width: 100%;
  height: 74px;
  /* margin: 77px 0 0; */
  /* padding: 13px 222px; */
  box-shadow: 0 14.9px 24.8px 0 rgba(153, 156, 157, 0.25);
  background-color: #ffd476;
  flex-grow: 0;
  font-family: 'VisbyCF-Bold';
  font-size: 23.4px;
  font-weight: 600;
  font-stretch: normal;
  font-style: bold;
  line-height: 2.02;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  border: none;
}
.reach-out-div h4{
 
 
  font-family: 'VisbyCF-Bold';
  font-size: 28px;
  font-weight: 600;
  font-stretch: normal;
  font-style: bold !important;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: left;
  color: #263238;
}
.address p{
  font-family: 'VisbyCF-Bold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #37474f;
  width:100%;

}
.email-div p{
  font-family: 'VisbyCF-Bold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #37474f;
  padding-top: 0.5rem;
}
.contact-div p{
  font-family: 'VisbyCF-Bold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #2b324f;
  padding-top: 0.5rem;
  
  
}
.find_us_text h4{

  font-family: 'VisbyCF-Bold';
  font-size: 28px !important;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.3px;
  text-align: left;
  color: #263238;
}
.find_us_social_icons{
  display: flex;
  gap: 0.6rem;
  align-items: left;
  text-align: left;

}
.find_us_social_icons img{
  /* width:40px;
  height:40px; */
  align-items: left;
  
}
.insta{
  width:100% !important;
}
.face{
  width:40px;
  height:40px;
}
.get_a_quote_head h1{
  font-family: 'VisbyCF-Bold';
  font-size: 60px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -1.2px;
  text-align: left;
  color: #030605;
}
.get_a_qoute_border{
  border-bottom: solid 2px rgb(164, 160, 160);
  width: 66%;
  background-color: #7d8080;
  opacity: 0.4;
  color: #7d8080 !important;
}
@media (min-width: 300px) and (max-width: 769px) {
  .form_bg {
    width: 100%;
    height: 914px;
    /* margin: 46px 145px 121px 153px; */
    padding: 45px;
    border-radius: 6px;
    background-color: #fff;
}

.get_a_quote_head h1 {
 
  font-size: 30px;

}
.main-right-react-div{
  margin-top: 1rem;
}
}