@font-face {
    font-family: 'RoxboroughCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

    src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
}

@font-face {
    font-family: 'VisbyCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

    src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
    font-family: 'VisbyCF-Bold';


    src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
}

.create_your_dreame_space_parent {
    background-image: url("../../assets/half_1.png");
    background-repeat: no-repeat;
    background-position: right top 00px;
    /* Adjusted position */
    background-size: 15%;
    /* Adjusted size */
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 50;
}

.create_your_dreame_space_content h1 {


    width: 547px;
    height: 10px;
    margin: 100px 633px 90px 0;
    font-family: 'RoxboroughCF';
    font-size: 82px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
}

.create_your_dreame_space_content_inner {
    padding: 1rem;
    /* border: solid 1px red; */
    width: 392px;
    height: 488px;
    margin: 1rem 0rem 0rem 0rem;
}

.create_your_dreame_space_content_inner:hover {
    .Dream_arrow {
        text-align: right;
        display: block;
    }



    background-color: #fff;
}

.create_your_dreame_space_content_inner img {
    width: 100px;
}

.create_your_dreame_space_content_inner h4 {
    width: 266px;

    flex-grow: 0;
    font-family: 'VisbyCF';
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
    padding-top: 0.5rem;
}

.create_your_dreame_space_content_inner p {
    width: 251px;

    flex-grow: 0;
    font-family: 'VisbyCF';
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 1px;
    text-align: left;
    color: #5c5e5e;
}

.Dream_arrow {
    text-align: right;
    display: none;
}
.create_your_dreame_space_border_down {
    width: 85%; /* Use 100% width to make the line span the entire width of its container */
    height: 2px !important;
    border-bottom: 2px solid #7d8080 !important;
    background-color: #7d8080;
    opacity: 0.5 !important;
    margin-left: auto; /* Align to the right */
}


@media (min-width: 300px) and (max-width: 770px) {
    .create_your_dreame_space_content h1 {
        width: 273px;
        height: 22px;
        margin: 42px 14px 42px 20.3px;
        font-family: 'RoxboroughCF';
        font-size: 38px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: -0.76px;
        text-align: left;
        color: #030605;
    }

    .create_your_dreame_space_parent {
        background-image: url("../../assets/half_1.png");
        background-repeat: no-repeat;
        background-position: right top 00px;
        /* Adjusted position */
        background-size: 35%;
        /* Adjusted size */
        width: 100%;
        height: 500px;
    }

    .create_your_dreame_space_content_inner {
        padding: 1rem;
        /* border: solid 1px red; */
        width: 300px;
        height: 388px;
        margin: 1rem 0rem 0rem 0rem;
    }

    .create_your_dreame_space_content_inner img {
        width: 40%;
    }

    .create_your_dreame_space_content_inner h4 {
        width: 266px;


        font-size: 26px;

        padding-top: 0.2rem;
    }
    .create_your_dreame_space_content_inner p {
        font-size: 18px;

    }
}
@media (min-width: 300px) and (max-width: 500px){

    .Dream_arrow {
        text-align: right;
        display: block;
        margin-top: -1rem;
    }

}
@media (min-width: 400px) and (max-width: 500px){



.create_your_dreame_space_content_inner {
    padding: 1rem;
    /* border: solid 1px red; */
    width: 370px;
    height: 430px;
    margin: 1rem 0rem 0rem 0rem;
}
}
@media (min-width: 700px) and (max-width: 800px){

.Dream_arrow {
    text-align: right;
    display: block;
    margin-top: -1rem;
}
.create_your_dreame_space_content h1 {
    width: 100%;
   
    font-size: 60px;
    padding-bottom: 1rem;
   
}
.create_your_dreame_space_content_inner{
    background-color: #fff !important;
}
}
/* Initial state of the cards (before animation) */
.custom-col {
    opacity: 0;
    transform: translateX(-100%);
    transition: all 0.8s ease-in-out;
}

/* Add the animation classes */
.animate-card-1 {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.2s;
}

.animate-card-2 {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.4s;
}

.animate-card-3 {
    opacity: 1;
    transform: translateX(0);
    transition-delay: 0.6s;
}

