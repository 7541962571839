@font-face {
  font-family: 'RoxboroughCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('./components/assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');

  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype'); */
}

@font-face {
  font-family: 'VisbyCF';
  /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */

  src: url('./components/assets/fonts/VisbyCF-Light.otf') format('opentype');
}

@font-face {
  font-family: 'VisbyCF-Bold';
  

  src: url('./components/assets/fonts/VisbyCF-Bold.otf') format('opentype');
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scrollbar */
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
  font-size: 50px;
}
.header-div{
  background-color: aqua;
  width:100%;
  height:100px;
}
body{
  background-color:rgba(248.0000004172325, 251.00000023841858, 250.00000029802322, 1) !important;
}
.header-container{
  position:fixed;
  width: 100%;
  height: 120px;
  
  z-index: 100;
  top:0px;
  background-color:rgba(248.0000004172325, 251.00000023841858, 250.00000029802322, 1) !important;
}
.header-container-active{
 
  
}

a{
  color:black;
  text-decoration: none !important;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  list-style: none !important;

}
a:hover{
  text-decoration: none;
  color:#040404 !important;
}
.active {
  color: #171616;
  border-bottom: 1px solid #030605; 

  /* height: 1px; */
  align-items: center;
 

  /* Other styles for the active state */
}

.withdraw-header a:hover{
  color:white !important;
}
.header-container-parent{
  max-width: 1400px;
  margin:auto;
  position:relative;
  height: 70px;
  padding-top: 30px;

}

.header-nav{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 300px;
  height: 70px;
  margin: auto;
}

/* @media (min-width:1000px){
  .header-nav{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 600px;
    height: 70px;
    margin: auto;
  }
} */
.links_nav_text{
  text-decoration: none;
  width: 61px;
  height: 20px;
  flex-grow: 0;
  font-family: 'VisbyCF' !important;
  font-size: 18px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: left;
  color: #030605;
}
@media (min-width: 1100px) {
  .sports-logo {
    padding-top: 30px;
  }
}


.sports-logo{
  position: absolute;
  left: 20px;
  top:8px;
  /* padding-top: 30px; */
}


.header-buttons{
 

  display: flex;
}
.btn-parent{
  position:absolute;
  display: flex;
  right: 20px;
  gap:20px;
  list-style: none !important;
  text-decoration: none !important;
}
.header-download{
  width: 128px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin: 0 0 17px 300px; */
  /* padding: 16px 26px; */
  background-color: #28887a;
  list-style: none !important;
  text-decoration: none !important;
 
  flex-grow: 0;
  font-family: 'VisbyCF';
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
  border: none;


  
}
.logo{
  width:160px;
  /* height:100px; */
}







.burger-menu{
  position:absolute;
  display: none;
    top:22px;
    right: 22px;
    
}

.burger-menu .fatb {
  color:#28887a;
}

.home-header a{
  color:white;
}


.home-header .active{
  color:#EA9528;
}
.none1-logo{
  display: block;
}

.none2-logo{
  display: none;
}



@media(max-width:1000px){
  .sports-logo{
    top:0px;
  }

  .none1-logo{
    display: none;
  }

  .none2-logo{
    display: block;
  }

  .btn-parent{
    position:static;
    gap:0px;
  }

.header-download{

  position:fixed;
  right: 76px;
  top:21px;
}
  .burger-menu{
 
    display: block;
    color:white;
   
  }


  /* .header-login{
      top:50vh;
      
      
  } */
  

  .header-nav{
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    background-color:rgba(248.0000004172325, 251.00000023841858, 250.00000029802322, 1) !important;
    position:relative;
    top:68px;
    gap:25px;
    left:0px;
    transition: all 2s ;
    margin-top: 0.2rem;;
    
  }

  .header-nav-active{
    left:-100%;
    transition: all 2s ;
  }
  .header-login{
    position:static;
  }



 .responsive-header-navlinks{
  width: 97px;
 }
 .responsive-header-navlinks2{
  width: 97px;
 }

 .header-download{
 
  font-family: 'VisbyCF';
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: center;
  color: #fff; 
  width: 98.5px;
  height: 33px;
  margin: 0.6rem 0.2rem 0px 17.6px;
  padding: 10px 18.2px 10px 19.2px;
  background-color: #28887a;

 }
 .logo{
  width:100px !important;
  height:60px !important;
 }
 .header-container-parent {
 
  padding-top: 10px;
}

}




@media(max-width:550px){
 

  .header-download{
    width: 100px;
    font-size: 10px;
    right: 64px;
  }

}

.none2-logo{
  width: 100px !important;
  top:50px !important;
}

/* @media(max-width:1000px){

  .header-nav{
  display: grid;
  grid-template-columns: repeat(5, column);
  width: 100vw;
  justify-content: center !important;
  height: 100vh;
  background-color: rgb(206, 199, 199);

  position: absolute;
  top: 68px;
  gap: 0 !important;
  left: 0px;
  padding: 6rem 6rem;
  transition: all 0.3s cubic-bezier(1, -0.08, 0.16, 1.81);
}
} */

@media (min-width: 1900px) and (max-width: 2000px) {
  .links_nav_text {
    
    font-size: 18px !important;
    font-family: 'VisbyCF';
 
}


}