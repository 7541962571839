
@font-face {
    font-family: 'RoxboroughCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */
  
    src: url('../../assets/fonts/Roxborough-CF/Roxborough\ CF.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'VisbyCF';
    /* src: url('../../assets/fonts/Roxborough-CF/Roxborough-CF.ttf') format('truetype'); */
  
    src: url('../../assets/fonts/VisbyCF-Light.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'VisbyCF-Bold';
  
  
    src: url('../../assets/fonts/VisbyCF-Bold.otf') format('opentype');
  }
.how_we_work_main_parent{
    background-color:rgba(248.0000004172325, 251.00000023841858, 250.00000029802322, 1) !important;

    height:auto;
    /* border: solid red 2px; */
    overflow: hidden;
    position: relative;

}
.parent-project-div{
    /* overflow-x: hidden !important; */
    position: relative;
}

.bg-rect {

  
    width: 100%;
    height: auto;
    position: absolute;
    z-index: 1;
    bottom: 0% ;
    
    left:-1%;


}

.Section-Title-de h1 {
    width: 827px;
    height: 190px;
    margin: 80px 0 90px 352px;
    font-family: 'RoxboroughCF';
    font-size: 82px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: right;
    color: #030605;
}

.Section-Title-work h1 {



    font-family: 'RoxboroughCF';
    font-size: 82px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.16;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
    padding-left: 1.7rem;

}
.work-right-img  img{
    width:98%;
}
.work-right-img {

    margin: 0px;
    
    /* align-items: end;
    justify-content: flex-end; */
    text-align:right;
    /* position: relative; */
}

.border-round {
    border: solid red 2px;
}

.border-roun {
    border: solid rgb(38, 0, 255) 2px;
}

.cards-project {
    width: 369px;
    height: 360px;
    flex-grow: 0;
    margin: 0 0 0rem 0;
    padding: 40px 40px 32px;
    background-color: #fff;
    /* border: solid rgb(30, 255, 0) 2px; */
}

.cards-project span {
    /* width: 78px; */
    /* height: 70px; */
    flex-grow: 0;
    /* margin: 0 211px 20px 0; */
    -webkit-text-stroke: 0 #000;
    font-family: 'WorkSans';
    font-size: 70px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 100%;
    letter-spacing: normal;
    text-align: left;
    color: #ffc74c;
}

.Project-presentation {

    flex-grow: 0;

    font-family: 'VisbyCF';
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
}

.Statement-of-the-problem {

    flex-grow: 0;

    font-family: 'VisbyCF';
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: normal;
    text-align: left;
    color: #030605;
}

.Development-of-renderings {

    flex-grow: 0;

    font-family: 'VisbyCF';
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 150%;
    letter-spacing: -0.32px;
    text-align: left;
    color: #030605;
}

.text-of-the-printing {

    flex-grow: 0;

    font-family: 'VisbyCF';
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
}
.design_parent .row > div {
    padding: 0 5px; /* Adjust the padding as needed */
}

.design_parent img {
    width: 100%;
    display: block;
}

.left-one {
    margin-right: 10px;
    padding-left:0 !important;
    margin-left: -4px; /* Adjust the gap between images */
}

.right-one {
    margin-left: 15px; /* Adjust the gap between images */
}

/* .design_parent{
    display: flex;
    width:100% !important;
    height:700px;
   
    gap: 0px;
   
}
.design_parent img{
    width:100% !important;

} */
.design_left {
  /* position: absolute;
  left: 0; */
  /* width:50%; */
  
  
}

.design_right {
    /* position: absolute;
    right: 0; */
    /* width:50%;
     */
}
/* .left-tw{
    position: absolute;
    right: 0;
    bottom:0rem;

}
.right-one{
    position: absolute;
    left: 0;
    top:0rem;
} */
.we_create_design_text p{

      
        font-family: 'VisbyCF';
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 150%;
        letter-spacing: normal;
        text-align: left;
        color: #030605;
        padding-top: 1rem;

}
@media (min-width: 1900px) and (max-width: 2000px) {
    
    .Section-Title-de h1 {
        width: 89%;
        height: 190px;
        margin:1rem 0rem 1rem 1rem;
        /* color: red !important; */
        float: right;
        padding-left: 1rem;
      
   
       
    }
    .work-right-img  img{
        width:91%;
    }
    .bg-rect {
        bottom: 5.5rem !important;
        left:-2.5%;

    }
    .Section-Title-work h1 {
    
        padding-left: 0rem !important;
    }

}
@media (min-width: 1701px) and (max-width: 1899px) {
   
    .work-right-img  img{
        width:91%;
    }
    .bg-rect {
        bottom: 3.5rem;
        left:-2.5%;

    }
    .Section-Title-work h1 {
    
        padding-left: 0rem !important;
    }

}
@media (min-width: 1600px) and (max-width: 1700px) {
    .Services-We-Provide h1 {
     
        margin: 86px 116px 15px 4px;
       
    }
    .Section-Title-de h1 {
        width: 73%;
      
        margin: 80px 0 90px 242px;
       
    }

    
    .work-right-img  img{
        width:93%;
    }
    .bg-rect {
        bottom: 3.5rem;
        left:-2.5%;

    }
    .Section-Title-work h1 {
    
        padding-left: 1rem !important;
    }

}
@media (min-width: 1501px) and (max-width: 1599px) {

    .Section-Title-de h1 {
        /* width: 70%; */
        height: 190px;
        margin: 80px 0 90px 35px;
     
        align-items: right;
        float: right;
      
   
       
    }
    .work-right-img  img{
        width:93%;
    }
    .bg-rect {
        bottom: 2.5rem;
        left:-2.5%;

    }
    .Section-Title-work h1 {
    
        padding-left: 0rem !important;
    }

}
@media (min-width: 1400px) and (max-width: 1500px) {
    .Section-Title-de h1 {
        width: 80%;
        height: 190px;
        margin: 80px 0 90px 3px;
        /* color: red !important; */
        float: right;
      
   
       
    }
    
    .work-right-img  img{
        width:97%;
    }
    .bg-rect {
        bottom: 1.2rem !important;
        left:-1.3%;
        /* border: solid green 2px; */
        width:100%;
        height:500px;

    }
    .Section-Title-work h1 {
    
        padding-left: 0.6rem !important;
    }

}
@media (min-width: 1300px) and (max-width: 1399px){
    .Section-Title-de h1 {
        width: 100%;
        height: 190px;
        margin: 80px 0 90px 5px;
      
   
       
    }

    .work-right-img  img{
        width:100%;
    }
    .bg-rect {
        bottom: 1.2rem;
        left:-5%;

    }
    .Section-Title-work h1 {
    
        padding-left: 0rem !important;
    }

}

@media (min-width: 1200px) and (max-width: 1299px){
    .Section-Title-de h1 {
        width: 100%;
        height: 190px;
        margin: 80px 0 90px 5px;
        padding-right: 1rem;
      
   
       
    }

    .work-right-img  img{
        width:100%;
    }
    .bg-rect {
        bottom: 1.2rem;
        left:3%;
        width:90%

    }
    .Section-Title-work h1 {
    
        padding-left: 0rem !important;
    }
    .cards-project {
        width: 300px;
        height: 360px;
        
    }
    .Development-of-renderings {

        font-size: 25px;
       
    }
    .Statement-of-the-problem {
        font-size: 25px;
      
    }
    .Project-presentation {

     
        font-size: 25px;
  
    }
    
}
@media (min-width: 1000px) and (max-width: 1199px){

    .work-right-img  img{
        width:94%;
    }
    .bg-rect {
        top: 30%;
        left:4%;
        width:90%

    }
    .Section-Title-work h1 {
    
        padding-left: 1.2rem !important;
    }
    .cards-project {
        width: 260px;
        height: 360px;
        padding: 0.5rem;
       gap: 10px;
   
        
    }
    .Development-of-renderings {

        font-size: 25px;
       
    }
    .Statement-of-the-problem {
        font-size: 25px;
      
    }
    .Project-presentation {

     
        font-size: 25px;
  
    }
    .Section-Title-work h1 {
      
        font-size: 62px;
      
    }
    .Section-Title-de h1 {
       
        margin: 80px 0 90px 92px;
        font-size: 62px !important;
        width:80%;
       
       
    }
}
@media (min-width: 900px) and (max-width: 999px){
    .btn-parent {
        position: static;
        /* margin: 3rem !important; */
    }
    .sports-logo {
        position: absolute;
        left: 90px !important;
        top: 8px;
        /* padding-top: 30px; */
    }

    .work-right-img  img{
        width:94%;
    }
    .bg-rect {
       top: 30%;
        left:0%;
        width:100%

    }
    .Section-Title-work h1 {
    
        padding-left: 1.2rem !important;
    }
    .cards-project {
        width: 230px;
        height: 360px;
        padding: 0.5rem;
       gap: 10px;
   
        
    }
    .Development-of-renderings {

        font-size: 25px;
       
    }
    .Statement-of-the-problem {
        font-size: 25px;
      
    }
    .Project-presentation {

     
        font-size: 25px;
  
    }
    .Section-Title-work h1 {
      
        font-size: 62px;
      
    }
}
@media (min-width: 300px) and (max-width: 899px){
    .work-right-img img {
        width: 100%;
        height: 450px;
        display: block;
    }
    .Section-Title-de h1 {
        width: 100%;
        height: 190px;
        margin:1rem 0rem 1rem 1rem;
        /* color: red !important; */
        float: right;
        padding-left: 1rem;
        font-size: 38px;
      
   
       
    }
    .left-one {
        margin-right: 0px !important;
        padding-left:0 !important;
        margin-left: -1px; /* Adjust the gap between images */
    }
    
    .right-one {
        margin-left: 0px !important; /* Adjust the gap between images */
    }
    .sports-logo {
        position: absolute;
        left: 24px !important;
        top: 8px;
        /* padding-top: 30px; */
    }
    .Section-Title-work h1 {

        font-size: 38px;
       padding-left: 0px !important;
    
    }
    .bg-rect {

  
     
      top:70% !important;
        left:-1.5%;
    
    
    }
    .cards-project {
        width: 300px !important;
        height: 360px;
        flex-grow: 0;
        margin-bottom: 0.3rem !important;
        padding: 40px 40px 32px;
        background-color: #fff;
        /* border: solid rgb(30, 255, 0) 2px; */
    }
    .Development-of-renderings {

        font-size: 26px;
       
    }
    .Statement-of-the-problem {
        font-size: 26px;
      
    }
    .Project-presentation {

     
        font-size: 26px;
  
    }
    .text-of-the-printing {

       
        font-size: 18px;
      
    }
    .cards-project span {
        /* width: 78px; */
        /* height: 70px; */
        flex-grow: 0;
        /* margin: 0 211px 20px 0; */
        -webkit-text-stroke: 0 #000;
        font-family: 'WorkSans';
        font-size: 60px;
       
    }
   
    
}
@media (min-width: 300px) and (max-width: 400px){

.bg-rect {
        top: 50% !important;
        left: -1.5%;
    }

}
@media (min-width: 401px) and (max-width: 500px){
    .bg-rect {

  
     
        top:48% !important;
          left:-1.5%;
      
      
      }
      .cards-project {
        width: 390px !important;
        height: 360px;
        flex-grow: 0;
        margin-bottom: 0.3rem !important;
        padding: 40px 40px 32px;
        background-color: #fff;
        /* border: solid rgb(30, 255, 0) 2px; */
    }
}
@media (min-width: 700px) and (max-width: 800px){
    .Section-Title-work h1 {
        font-size: 60px;
       
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    .cards-project {
        width: 450px !important;
        height: 400px;
        flex-grow: 0;
        margin-bottom: 0.3rem !important;
        padding: 40px 40px 32px;
        background-color: #fff;
        /* border: solid rgb(30, 255, 0) 2px; */
        
    }
    .bg-rect {
        top: 50% !important;
        left: 4%;
    }
    .Section-Title-de h1 {
        width: 100%;
        height: 190px;
        margin: 1rem 0rem 1rem 0rem !important;
        /* color: red !important; */
        float: left !important;
        padding-left: 3rem;
        font-size: 38px;
        font-size: 60px;
    }
}


@media (min-width: 800px) and (max-width: 900px){
    .cards-project {
        width: 500px !important;
        height: 460px !important;
        flex-grow: 0;
        margin-bottom: 0.3rem !important;
        padding: 40px 40px 32px;
        background-color: #fff;
        /* border: solid rgb(30, 255, 0) 2px; */
    }
    .bg-rect {
        top: 50% !important;
        left: 4%;
    }
    .Section-Title-de h1 {
        width: 100%;
        height: 190px;
        margin: 4rem 0rem 1rem 1rem;
        /* color: red !important; */
        float: right;
        padding-left: 1rem;
        font-size: 60px;
    }
    .Section-Title-work h1 {
        font-size: 60px;
        padding-left: 0px !important;
    }

}
